import { uploadMedia } from "src/ApiConfig/service";
import { Contract } from "@ethersproject/contracts";
import { toast } from "react-hot-toast";

export function sortAddress(add) {
  const sortAdd = `${add?.slice(0, 6)}...${add?.slice(add.length - 4)}`;
  return sortAdd;
}

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

export function getContract(address, ABI, library, account) {
  return new Contract(address, ABI, getProviderOrSigner(library, account));
}

export function downloadCsv() {}

export const UpoadCompanyMedia = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  const id = toast.loading("Uploading media");
  const res = await uploadMedia(formData);

  if (res.responceCode === 200) {
    toast.success(res?.responseMessage || "Media uploaded successsfully", {
      id: id,
    });

    return res?.result;
  } else {
    toast.error(res?.responseMessage || "Unable to upload media", {
      id: id,
    });
  }
};

import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Home/auth/Login/index")),
  },

  {
    exact: true,
    path: "/forget-password",
    layout: LoginLayout,

    component: lazy(() =>
      import("src/views/pages/Home/auth/forget-password/index")
    ),
  },

  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,

    component: lazy(() =>
      import("src/views/pages/Home/auth/reset-password/index")
    ),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: HomeLayout,

    component: lazy(() =>
      import("src/views/pages/Home/auth/Verify-Otp/VerifyOtp")
    ),
  },
  {
    exact: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Dashboard")),
  },

  {
    exact: true,
    path: "/terms-condition",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticContentManagement/Termcondition")
    ),
  },
  {
    exact: true,
    path: "/edit-termsCondition",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/StaticContentManagement/EditTermsCondition"
      )
    ),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticContentManagement/PrivacyPolicy")
    ),
  },
  {
    exact: true,
    path: "/edit-privacy",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticContentManagement/EditPrivacy")
    ),
  },
  {
    exact: true,
    path: "/view-static-content",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticContentManagement/AboutUs")
    ),
  },
  {
    exact: true,
    path: "/edit-static-content",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticContentManagement/EditAboutUs")
    ),
  },
  {
    exact: true,
    path: "/tutorial-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticContentManagement/StaticView")
    ),
  },

  {
    exact: true,
    path: "/notification-view",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/NotificationManagement/NotificationView"
      )
    ),
  },
  {
    exact: true,
    path: "/notification-edit",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/NotificationManagement/NotificationEdit"
      )
    ),
  },

  {
    exact: true,
    path: "/notification-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/NotificationManagement/NotificationManagement"
      )
    ),
  },

  {
    exact: true,
    path: "/static-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/StaticContentManagement/StaticManagement"
      )
    ),
  },
  {
    exact: true,
    path: "/add-social",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticContentManagement/AddSocial")
    ),
  },
  {
    exact: true,
    path: "/edit-privacy",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticContentManagement/EditPrivacy")
    ),
  },
  {
    exact: true,
    path: "/tutorial-view",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticContentManagement/TutorialView")
    ),
  },
  {
    exact: true,
    path: "/tutorial-edit",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticContentManagement/TutorialEdit")
    ),
  },
  {
    exact: true,
    path: "/user-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/UserManagement")
    ),
  },
  {
    exact: true,
    path: "/user-view",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/ViewUser")
    ),
  },
  {
    exact: true,
    path: "/add-user",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/AddUser.js")
    ),
  },
  {
    exact: true,
    path: "/edit-user",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/EditUser")
    ),
  },
  {
    exact: true,
    path: "/edit-company",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/CompanyManagement/CompanyUpdate")
    ),
  },

  {
    exact: true,
    path: "/my-profile",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Settings/Settings")
    ),
  },
  {
    exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Settings/Changepassword")
    ),
  },

  {
    exact: true,
    path: "/add-tutorial",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticContentManagement/Tutorial")
    ),
  },
  {
    exact: true,
    path: "/add-event",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/EventManagement/AddEvent")
    ),
  },
  {
    exact: true,
    path: "/event-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/EventManagement/EventManagement")
    ),
  },
  {
    exact: true,
    path: "/event-view",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/EventManagement/EventView")
    ),
  },
  {
    exact: true,
    path: "/event-update",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/EventManagement/EventUpdate")
    ),
  },
  {
    exact: true,
    path: "/advertisement",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/AdvertisementManagement/AdvertisementManagement"
      )
    ),
  },

  {
    exact: true,
    path: "/view-advertisement",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/AdvertisementManagement/ViewAdvertisement"
      )
    ),
  },
  {
    exact: true,
    path: "/edit-advertisement",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/AdvertisementManagement/EditAdvertisement"
      )
    ),
  },
  {
    exact: true,
    path: "/add-advertisement",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/AdvertisementManagement/AddAdverstisement"
      )
    ),
  },
  {
    exact: true,
    path: "/approvalList",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/AdvertisementManagement/AppovalList")
    ),
  },
  {
    exact: true,
    path: "/company-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/CompanyManagement/CompanyManagementList"
      )
    ),
  },
  {
    exact: true,
    path: "/company-view",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/CompanyManagement/CompanyView")
    ),
  },
  {
    exact: true,
    path: "/banner-update",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/CompanyManagement/CompanyUpdate")
    ),
  },
  {
    exact: true,
    path: "/add-company",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/CompanyManagement/AddCompany")
    ),
  },
  {
    exact: true,
    path: "/notification-add",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/NotificationManagement/AddNotification")
    ),
  },

  // {
  //   exact: true,
  //   path: "/admin-management",
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Dashboard/Profile/AdminProfile")
  //   ),
  // },

  // -------------admin------------------
  {
    exact: true,
    path: "/user-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/UserManagement")
    ),
  },
  {
    exact: true,
    path: "/user-view",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/ViewUser")
    ),
  },
  {
    exact: true,
    path: "/category-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/CategoryManagement/CategoryManagement")
    ),
  },
  {
    exact: true,
    path: "/edit-category",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/CategoryManagement/EditCategoryManagement"
      )
    ),
  },
  {
    exact: true,
    path: "/view-category",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/CategoryManagement/ViewCategory")
    ),
  },
  {
    exact: true,
    path: "/add-category",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/CategoryManagement/AddCategory")
    ),
  },
  {
    exact: true,
    path: "/notification",
    layout: DashboardLayout,
    component: lazy(
      () => import("src/views/pages/Dashboard/Notification/Notification")
      // import("src/views/pages/Dashboard/NotificationManagement/NotificationManagement.js")
    ),
  },
  {
    exact: true,
    path: "/editmedbound-times",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Notification/EditNotification")
    ),
  },
  {
    exact: true,
    path: "/faq-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FaqManagement/FaqList")
    ),
  },
  {
    exact: true,
    path: "/add-faq",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FaqManagement/AddFaq")
    ),
  },
  {
    exact: true,
    path: "/view-faq",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FaqManagement/ViewFaq")
    ),
  },
  {
    exact: true,
    path: "/update-faq",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FaqManagement/UpdateFaq")
    ),
  },
  {
    exact: true,
    path: "/help-center",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/HelpCenter/HelpCenterList")
    ),
  },
  {
    exact: true,
    path: "/add-helpcenter",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/HelpCenter/AddHelpCenter")
    ),
  },
  {
    exact: true,
    path: "/view-helpcenter",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/HelpCenter/ViewHelpCenter")
    ),
  },
  {
    exact: true,
    path: "/update-helpcenter",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/HelpCenter/UpdateHelpCenter")
    ),
  },

  {
    exact: true,
    path: "/404",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
